<template>
  <div class="flightdetails_item_dtls">
    <div class="d-flex align-items-center flightdetails_item_dtls_top">
      <div class="airlogobox d-flex align-items-center">
        <img :src="airlineLogo" v-if="!unconfirmedUnauthorized">
        <h4>{{airlineName}}</h4>
      </div>
      <h5>{{ $t("sabre.search-result.box.flight-number")}}: {{departureFlightNumber}}  </h5>
      <h5>{{$t("sabre.search-result.box.department-name", {dest: transDepartmentName})}}  </h5>
    </div>
    <div class="d-flex align-items-center  flightdetails_item_dtls_bottom" :class="{'flex-column': device==='mobile'}">
      <div class="box_one d-flex align-items-center justify-content-between">
        <div class="box_one_text">
          <h4>
            <strong>{{ $t("sabre.search-result.box.date-time", { date: departureDate, time: departureTime, dayName: departureDayName}) }} </strong>
            <span>{{departureCityCode}}</span>
            {{`${departureCityName} (${departureCityEnName})`}}
            <small v-if="!unconfirmedUnauthorized">{{ $t("sabre.search-result.box.flight-duration", elapsedTime)}}</small>
          </h4>
        </div>
        <div class="box_one_midel">
          <div class="line"></div>
        </div>
        <div class="box_one_text">
          <h4>
            <strong>{{ $t("sabre.search-result.box.date-time", { date: arrivalDate, time: arrivalTime, dayName: arrivalDayName}) }}</strong>
            <span>{{arrivalCityCode}}</span>
            {{`${arrivalCityName} (${arrivalCityEnName})`}}
          </h4>
        </div>
      </div>
      <div class="box_two  d-flex align-items-center justify-content-end">
        <div class="imgifo">
          <img src="/assets/sabre-img/trolley_allow.png" alt="#" v-if="allowCabinBaggage">
          <img src="/assets/sabre-img/trolley_disallow.png" alt="#" v-else>
        </div>
        <div class="imgifo">
          <img src="/assets/sabre-img/Suitcase_allow.png" alt="#" v-if="allowCheckinBaggage">
          <img src="/assets/sabre-img/Suitcase_disallow.png" alt="#" v-else>
        </div>

        <tooltip-item :allowCheckinBaggage="allowCheckinBaggage" :allowCabinBaggage="allowCabinBaggage"
          :checkinBaggageDescription="checkinBaggageDescription"
          :cabinBaggageDescription="cabinBaggageDescription"/>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import searchResultBoxMixin from '../searchResultBoxMixin';

export default {
  props: {
    department: {
      type: String,
      default: '',
    },
  },
  mixins: [searchResultBoxMixin],
  components: {
    TooltipItem: () => import('@/sabre/common/atom/tooltipItem'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    transDepartmentName() {
      const { department } = this;
      return this.$t(`sabre.search-panel.${department}`);
    },
  },
};
</script>
